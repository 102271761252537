import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// 404 not found routing
const PageNotFound = Loadable(lazy(() => import('views/pages/notfound/PageNotFound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const NotFoundRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/404',
            element: <PageNotFound />
        },
        {
            path: '*',
            element: <Navigate to="/404" />
        }
    ]
};

export default NotFoundRoutes;
