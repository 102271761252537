import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.png';

// ==============================|| LOGO SECTION ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <>
            <img src={logo} alt="MagTapp" width="100" />
        </>
    );
};

export default Logo;
