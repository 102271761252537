import { useEffect, useState } from 'react';
// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { getRoleBasedRoutes } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { getSideNavList, menuState } from '../../../../features/menu/menuSlice';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    const dispatch = useDispatch();

    const menu = useSelector(menuState);

    const [navList, setNavList] = useState();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('profile'));

        if (menu.sideNavList.length > 0)
            getNavLink();
        else
            dispatch(getSideNavList(getRoleBasedRoutes(menuItem.items, user, 'menu rerender')));

    }, [menu.sideNavList]);

    const getNavLink = () => {

        const result = menu.sideNavList.map((item) => {

            if (item.type === 'group')
                return <NavGroup key={item.id} item={item} />;
            else
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        });

        setNavList(result);
    }

    return <>{navList}</>;
};

export default MenuList;
