import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getWordCountAsync = createAsyncThunk('getWordCountAsync', async (dispatch) => {
    dispatch(loaderStart());

    return await Response('WORD_COUNT')
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(updateExistingWordCount(false));
                return data.resultObj;
            }
            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const getExistingWordCountAsync = createAsyncThunk(
    'getExistingWordCountAsync', async ({ dispatch, isRefresh }) => {
    dispatch(loaderStart());

    return await Response('EXISTING_WORDS_COUNT', { isRefresh })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(updateExistingWordCount(true));
                return data.resultObj.result;
            }
            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isExistingWordCount: false,
        wordCountData: []
        // sourceLanguageData: {},
    },
    reducers: {
        updateExistingWordCount: (state, { payload }) => {
            state.isExistingWordCount = payload;
        },
        clearWordState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {
        [getWordCountAsync.fulfilled]: (state, { payload }) => {
            if (!state.isExistingWordCount) state.wordCountData = payload;
        },

        [getExistingWordCountAsync.fulfilled]: (state, { payload }) => {
            if (state.isExistingWordCount) state.wordCountData = payload;
        }
    }
});

export const dashboardState = (state) => state.dashboard;

export const { updateExistingWordCount ,clearWordState } = dashboardSlice.actions;

export default dashboardSlice.reducer;
