import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getApprovalWordAsync = createAsyncThunk(
    'getApprovalWordAsync', async ({dispatch, isApprove }) => {

        dispatch(loaderStart());

        return await Response('GET_PENGING_WORDS', {isApprove} )
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {

                    return data?.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const wordVerifySlice = createSlice({

    name: "wordVerfiy",

    initialState: {
        wordVerifyData: {}
    },
    reducers: {
        clearWordState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [getApprovalWordAsync.fulfilled]: (state, { payload }) => {
            state.wordVerifyData = payload
        },


    }
});

export const { clearWordState } = wordVerifySlice.actions;

export const wordVerifyState = (state) => state.wordVerfiy;

export default wordVerifySlice.reducer;