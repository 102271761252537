// assets
import { IconBrandDribbble, IconApps } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = {
    IconBrandDribbble,
    IconApps
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'verify-word',
            title: 'Verify Word',
            type: 'item',
            url: '/utils/verify-word',
            icon: icons.IconApps,
            breadcrumbs: true,
            role: authRoles.admin
        },
        {
            id: 'word-Assigned',
            title: 'Word Assigned',
            type: 'item',
            url: '/utils/word-assigned',
            icon: icons.IconApps,
            breadcrumbs: true,
            role: authRoles.manager
        },
        {
            id: 'hindi-Word-List',
            title: 'Word Verification',
            type: 'item',
            url: '/utils/word-verification',
            icon: icons.IconApps,
            breadcrumbs: true,
            role: authRoles.user
        }
    ]
};

export default utilities;
