import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';


export const profileListAsync = createAsyncThunk(
    'profileListAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('GET_CURRENT_USER')
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success)
                    return data.profile

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
);


export const profileSlice = createSlice({
    name: "profile",
    initialState: {

        profileData: []
    },

    extraReducers: {

        [profileListAsync.fulfilled]: (state, { payload }) => {

            state.profileData = payload
        },
    }
});


export const profileState = (state) => state.profile;

export default profileSlice.reducer;