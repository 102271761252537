import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';
import { getApprovalWordAsync } from 'features/verifyWord/verifyWordSlice';

export const addWordAsync = createAsyncThunk(
    'addWordAsync', async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('ADD_WORD', postData)
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    const newObject = window.localStorage.getItem("profile");
                    const localData = JSON.parse(newObject);
                    const userId = localData._id;
                    dispatch(getWordByUserIdAsync({ userId, dispatch }))
                    dispatch(setAlertMessage("Word Added successfully"))
                    return data?.resultObj.data;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const getWordByUserIdAsync = createAsyncThunk(
    'getWordByUserIdAsync', async ({ userId, dispatch }) => {
        dispatch(loaderStart());

        return await Response('GET_ASSIGNED_WORD', { userId })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const editWordByIdAsync = createAsyncThunk(
    'editWordByIdAsync', async ({ id, dispatch }) => {
        dispatch(loaderStart());

        return await Response('GET_WORD_BY_ID', { id })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    return data.resultObj;
                }

                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const updateWordAsync = createAsyncThunk(
    'updateWordAsync', async ({ dispatch, postData }) => {
        dispatch(loaderStart());
        console.log("first", postData)
        return await Response('UPDATE_WORD', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    const newObject = window.localStorage.getItem("profile");
                    const localData = JSON.parse(newObject);
                    const userId = localData._id;
                    dispatch(getWordByUserIdAsync({ userId, dispatch }))
                    dispatch(setAlertMessage("Word Updated successfully"))
                    return data.resultObj;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const upateWordStatusAsync = createAsyncThunk(
    'upateWordStatusAsync', async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('WORD_SEND_TO_APPROVE', postData)

            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    const newObject = window.localStorage.getItem("profile");
                    const localData = JSON.parse(newObject);
                    const userId = localData._id;
                    const isApprove = "Pending"
                    {localData.user_type === "user" ?
                    dispatch(getWordByUserIdAsync({ userId, dispatch })) :
                    dispatch(getApprovalWordAsync({dispatch, isApprove}), (setAlertMessage("Word status updated successfully")))}
                    dispatch(setAlertMessage("Word status updated successfully"))
                    return data.resultObj;

                }

                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const wordSlice = createSlice({

    name: "wordAssigned",

    initialState: {

        editWordData: [],
    },
    reducers: {
        clearWordState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [editWordByIdAsync.fulfilled]: (state, { payload }) => {
            state.editWordData = payload
        },

        [getWordByUserIdAsync.fulfilled]: (state, { payload }) => {
            state.assignedWordData = payload
        },
    }
});

export const { clearWordState } = wordSlice.actions;

export const wordState = (state) => state.wordAssigned;

export default wordSlice.reducer;