import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getWordBySourceLangAsync = createAsyncThunk(
    'getWordBySourceLangAsync', async ({ dispatch, sourceLanguage }) => {
        dispatch(loaderStart());
        
        return await Response('WORD_BY_SOURCE_LAN', { sourceLanguage })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(updateFilteredState(1))
                    console.log(data, "1 Fired")
                    return data.resultObj;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const getExistingWordBySourceLangAsync = createAsyncThunk(
    'getExistingWordBySourceLangAsync', async ({ dispatch, language, page, limit, nextpage }) => {
        dispatch(loaderStart());
        
        return await Response('EXISTING_WORDS_LIST', { language, page, limit, nextpage })
            .then((data) => {

                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(updateFilteredState(2))
                    return data.resultObj;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const getWordAsync = createAsyncThunk(
    'getWordAsync', async (dispatch) => {
        dispatch(loaderStart());

        return await Response('GET_ALL_WORDS')
            .then((data) => {
                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(updateFilteredState(0))

                    return data?.resultObj.data;
                }
                return [];
            })
            .catch((error) => {

                dispatch(loaderEnd())

                dispatch(setAlertMessage(getErrorMessage(error.message)))

                return [];
            })
    }
);

export const assignWordAsync = createAsyncThunk(
    'assignWordAsync', async ({ dispatch, formData }) => {
        dispatch(loaderStart());

        return await Response('ASSIGNED_WORD', formData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(getWordAsync(dispatch))
                    dispatch(setAlertMessage("Word assigned successfully"));
                    return data;

                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const wordSlice = createSlice({

    name: "word",

    initialState: {
        isFilterData: 0,
        wordData: [],
        assignedWordData: [],
        selectedLanguage: {}
    },
    reducers: {
        clearWordState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        },
        updateFilteredState: (state, { payload }) => {
            state.isFilterData = payload;
        },
        setSelectedLanguage: (state, { payload }) => {
            state.selectedLanguage = payload;
        }
    },
    extraReducers: {

        // Get
        [getWordAsync.fulfilled]: (state, { payload }) => {
            if (state.isFilterData === 0)
                state.wordData = payload
        },

        [getWordBySourceLangAsync.fulfilled]: (state, { payload }) => {
            if (state.isFilterData === 1)
            state.wordData = payload
        },

        [getExistingWordBySourceLangAsync.fulfilled]: (state, { payload }) => {
            if (state.isFilterData === 2)
            state.wordData = payload
        },

    }
});

export const { clearWordState, updateFilteredState, setSelectedLanguage } = wordSlice.actions;

export const wordState = (state) => state.word;

export default wordSlice.reducer;