import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { authRoles } from '../config/roles';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
//Get Word List
const WordList = Loadable(lazy(() => import('../views/pages/word/wordlist')));
//Assigned Word
const WordAssigned = Loadable(lazy(() => import('../views/pages/wordassigned/wordAssigned')));
//Verify Word
const VerifyWord = Loadable(lazy(() => import('../views/pages/verifyword/verifed')));
// Profile
const Profile = Loadable(lazy(() => import('../views/pages/profile/Profile')));
// ManageUser
const ManageUser = Loadable(lazy(() => import('../views/pages/manageuser/ManageUser')));
//Verifying Hindi Words
const HindiWordVerification = Loadable(lazy(() => import('../views/pages/hindiWords/hindiWords')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
            role: authRoles.user
        },
        {
            path: '/utils/word-list/:tab',
            element: <WordList />,
            role: authRoles.admin
        },
        {
            path: '/utils/verify-word',
            element: <VerifyWord />,
            role: authRoles.admin
        },
        {
            path: '/utils/word-assigned',
            element: <WordAssigned />,
            role: authRoles.manager
        },
        {
            path: '/utils/view-profile',
            element: <Profile />,
            role: authRoles.user
        },
        {
            path: '/utils/word-verification',
            element: <HindiWordVerification />,
            role: authRoles.admin
        },
        {
            path: '/utils/manage-user',
            element: <ManageUser />,
            role: authRoles.admin
        }
    ]
};

export default MainRoutes;
