import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';
import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getHindiWordAsync = createAsyncThunk(
    'getHindiWordAsync', async ({ dispatch, page, limit, statusType }) => {
        dispatch(loaderStart());

        return await Response('GET_ALL_HINDI_WORDS', { page, limit, statusType })
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    return { data: data.resultObj.data, link: data.resultObj.link, page, limit };
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const getByWordIdAsync = createAsyncThunk(
    'getByWordIdAsync', async ({ dispatch, wordStatus, wordId }) => {
        dispatch(loaderStart());

        return await Response('GET_BY_WORDID', { wordId, wordStatus })
            .then((data) => {

                dispatch(loaderEnd());
                if (data.success) {
                    return data.resultObj;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const updatWordStatusAsync = createAsyncThunk(
    'updatWordStatusAsync', async ({ dispatch, postData, refreshPage }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_WORD_STATUS', postData)
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(getHindiWordAsync({ dispatch, ...refreshPage }));
                    dispatch(setAlertMessage("Word status updated successfully"));
                    return data;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const updatImageByWordAsync = createAsyncThunk(
    'updatImageByWordAsync', async ({ dispatch, postData, refreshPage }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_IMAGE_BY_WORD', postData)
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(getHindiWordAsync({ dispatch, ...refreshPage }));
                    dispatch(setAlertMessage("Image updated successfully"));
                    return data;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const getImageByWordAsync = createAsyncThunk(
    'getImageByWordAsync', async ({ dispatch, word }) => {
        dispatch(loaderStart());

        return await Response('GET_IMAGE_BY_WORD', { word })
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(setAlertMessage("Image fetch successfully"));
                    return data.resultObj.images;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const updateWordAsync = createAsyncThunk(
    'updateWordAsync', async ({ dispatch, postData, refreshPage }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_WORD', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {
                    dispatch(getHindiWordAsync({ dispatch, ...refreshPage }));
                    dispatch(setAlertMessage("Word updated successfully"));
                    return data;
                }

                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const updatPrioritizedToVerifiedAsync = createAsyncThunk(
    'updatPrioritizedToVerifiedAsync', async ({ dispatch, postData, refreshPage }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_PRIORITIZED_TO_VERIFIED', postData)
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(getHindiWordAsync({ dispatch, ...refreshPage }));
                    dispatch(setAlertMessage("Word status updated successfully to verified"));
                    return data;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const updatLaterToAnyAsync = createAsyncThunk(
    'updatLaterToAnyAsync', async ({ dispatch, postData, refreshPage }) => {
        dispatch(loaderStart());

        return await Response('UPDATE_LATER_TO_ANY', postData)
            .then((data) => {
                dispatch(loaderEnd());
                if (data.success) {
                    dispatch(setAlertMessage("Word status updated successfully"));
                    dispatch(getHindiWordAsync({ dispatch, ...refreshPage }));
                    return data;
                }
                return [];
            })

            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return [];
            })
    }
);

export const hindiWordSlice = createSlice({

    name: "hindiWord",

    initialState: {
        imageLink: [],
        hindiWordData: [],
        editWordData: [],
        imageByword: [],
        totalPages: 0
    },
    reducers: {
        clearHindiWordState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        // Get
        [getHindiWordAsync.fulfilled]: (state, { payload }) => {
            const { pages, words } = payload.data === undefined ? { pages: 0, words: [] } : payload.data;
            const { page, limit, link } = payload;
            state.imageLink = link;
            state.totalPages = pages;

            if (words) {
                if (page === 1)
                    state.hindiWordData = words;
                else {
                    const start = (page - 1) * limit;
                    state.hindiWordData.splice(start, limit);
                    state.hindiWordData = [...state.hindiWordData, ...words]
                }
            }
        },

        [getByWordIdAsync.fulfilled]: (state, { payload }) => {
            state.editWordData = payload
        },

        [getImageByWordAsync.fulfilled]: (state, { payload }) => {
            state.imageByword = payload
        },
    }
});

export const { clearHindiWordState } = hindiWordSlice.actions;

export const hindiWordState = (state) => state.hindiWord;

export default hindiWordSlice.reducer;