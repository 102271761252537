export default {
    SUPER_ADMIN: 'SUPER_ADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER',
    EDITER: 'EDITER',
    MANAGER: 'MANAGER'
}

export const authRoles = {
    sa: ['SA'],
    admin: ['SA', 'ADMIN'],
    editer: ['SA', 'EDITER'],
    manager: ['SA', 'USER', 'EDITER'],
    user: ['SA', 'ADMIN', 'EDITER', 'USER']
}