import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import { store } from 'app/store';

// style + assets
import 'assets/scss/style.scss';

// Custom Loader
import CustomLoader from './ui-component/CustomLoader/CustomLoader';

// Custom Alert
import CustomAlert from './ui-component/CustonAlert/CustomAlert';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <CustomLoader />
            <CustomAlert />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

