// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconUser, IconUsers } from '@tabler/icons';
import { authRoles } from 'config/roles';

// constant
const icons = {
    IconUser,
    IconUsers
};

// ==============================|| PROFILES MENU ITEMS ||============================== //

const profile = {
    id: 'profile',
    title: 'Profile',
    type: 'group',
    children: [
        {
            id: 'view-profile',
            title: 'Edit Profile',
            type: 'item',
            url: '/utils/view-profile',
            icon: icons.IconUser,
            breadcrumbs: true,
            role : authRoles.user,
        },
        {
            id: 'manage',
            title: 'Manage User',
            type: 'item',
            url: '/utils/manage-user',
            icon: icons.IconUsers,
            breadcrumbs: true,
            role : authRoles.admin
        },
    ]
};

export default profile;
