import { configureStore } from '@reduxjs/toolkit';
import menuSlice from 'features/menu/menuSlice';
import authSlice from 'features/auth/authSlice';
import wordVerifySlice from 'features/verifyWord/verifyWordSlice';
import userSlice from 'features/user/userSlice';
import loaderSlice from 'features/loader/loaderSlice';
import wordSlice from 'features/word/wordsSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import profileSlice from 'features/profile/profileSlice';
import manageUserSlice from 'features/manageUser/manageUserSlice';
import wordAssignedSlice from 'features/assignedWord/wordAssignedSlice';
import hindiWordSlice from 'features/hindiWord/hindiWordSlice';

export const store = configureStore({
    reducer: {
        menu: menuSlice,
        auth: authSlice,
        user: userSlice,
        loader: loaderSlice,
        word: wordSlice,
        wordVerfiy: wordVerifySlice,
        dashboard: dashboardSlice,
        currentprofile: profileSlice,
        wordAssigned: wordAssignedSlice,
        users: manageUserSlice,
        hindiWord: hindiWordSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})