export default {
    // User EndPoints
    LOGIN_USER: 'POST /user/add_userlogin',
    GET_USERS: ' /user/get_user',

    // Words EndPoints
    GET_ALL_WORDS: ' /word/get_word',
    ASSIGNED_WORD: 'POST /word/get_assign_language',
    GET_ASSIGNED_WORD: '/word/get_by_userId#',
    GET_WORD_BY_ID: '/word/get_word#',
    ADD_WORD: 'POST /word/add_all_word',
    UPDATE_WORD: 'PATCH /word/',
    WORD_COUNT: '/word/count_source_language',
    WORD_BY_SOURCE_LAN: 'POST /word/get_source_language',
    EXISTING_WORDS_COUNT: '/existing-words/count?',    
    EXISTING_WORDS_LIST: '/existing-words/?',

    // USER END-POINTS //
    ADD_USER: 'POST /manageuser',
    DELETE_USER: 'DELETE /manageuser#',
    UPDATE_PASSWORD: 'POST /manageuser/updatePassword',
    UPDATE_USER:'POST /manageuser/update/profile',
    GET_ALL_USERS: 'POST /manageuser/get',
    UPDATE_USER_BY_ID:"GET /manageuser#",
    WORD_SEND_TO_APPROVE: 'POST /word/add_assignword',
    GET_PENGING_WORDS: '/word/get_words_by_approve_status?',
    
    //Hindi Words
    GET_ALL_HINDI_WORDS : '/hindiWords/scrappedWords?',
    UPDATE_WORD_STATUS : 'POST /hindiWords/rearrange_words',
    UPDATE_WORD_AS_VERIFIED : 'POST /hindiWords/verified',
    GET_BY_WORDID : '/hindiWords/word_by_id?',
    GET_IMAGE_BY_WORD : '/hindiWords/image?',
    UPDATE_IMAGE_BY_WORD : 'POST /hindiWords/update',
    UPDATE_WORD : 'POST /hindiWords/update_priority_and_updateLater',
    UPDATE_PRIORITIZED_TO_VERIFIED : 'POST /hindiWords/prioritized_to_verified',
    UPDATE_LATER_TO_ANY : 'POST /hindiWords/update_later_to_verified_or_prioritized'
};
