import { createSlice } from '@reduxjs/toolkit';

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        isLoading: false,
        message : ''
    },
    reducers: {
        loaderStart: (state) => {
            state.isLoading = true
        },
        loaderEnd: (state) => {
            state.isLoading = false
        },
        setAlertMessage: (state, { payload }) => {
            state.message = payload
        }
    }
});

export const { loaderStart, loaderEnd, setAlertMessage } = loaderSlice.actions;

export const loaderState = (state) => state.loader;

export default loaderSlice.reducer;