
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import NotFoundRoutes from './NotFoundRoutes';
import config from 'config';

import { getRoleBasedRoutes } from '../utils';

// ==============================|| ROUTING RENDER ||============================== //


export default function ThemeRoutes() {

    let Routers = [
        AuthenticationRoutes,
        MainRoutes,
        NotFoundRoutes
    ];

    const user = JSON.parse(localStorage.getItem('profile'));

    const routes = getRoleBasedRoutes(Routers, user, 'routes');

    return useRoutes(routes, config.basename);
}
