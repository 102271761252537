
export const flat = (array) => {
    var result = []
    array.forEach(function (a) {
        result.push(a)
        if (Array.isArray(a.children)) {
            result = result.concat(flat(a.children))
        }
    })
    return result
}


export const getUserRoleAuthStatus = (pathname, routes) => {

    const user = JSON.parse(localStorage.getItem("profile"));

    if (!user) {
        return false
    }

    const matched = routes.find((r) => r.path === pathname)

    const authenticated = matched && matched.role && matched.role.length
        ? matched.role.includes(user.user_type.toUpperCase())
        : true;


    return authenticated
}

const filterRoles = (array, user) => {

    const result = array.children.map((childItem) => {

        if (Array.isArray(childItem.role)) {
            
            if (childItem.role.includes(user?.user_type.toUpperCase())) {
                //  if (childItem.role.includes("USER")) {
                return childItem;
            }
        }
        else {
            if (Array.isArray(childItem.children)) {

                childItem = getRoleBasedRoutes(childItem);
            }

            return childItem;
        }

    }).filter((child) => child != undefined);


    return result;
}

export const getRoleBasedRoutes = (array, user, type) => {

    let result;

    if (!user) {

        if (Array.isArray(array)) {

            result = array.map((item) => {

                if (Array.isArray(item.children)) {

                    item = { ...item, children: filterRoles(item, user) }
                }

                return item;
            })

            return result;
        }
    }

    if (Array.isArray(array)) {

        result = array.map((item) => {

            if (Array.isArray(item.children)) {

                item = { ...item, children: filterRoles(item, user) }
            }

            return item;
        })

    }
    else {

        if (typeof (array) === "object") {

            if (Array.isArray(array.children)) {

                array.children = filterRoles(array, user)
            }
        }

        result = array;
    }

    return result.filter(item => item.children.length > 0);

}

export const getErrorMessage = (message) => {

    let displayMessage = '';

    if (typeof message === "string") {

        displayMessage = message;
    }
    else if (typeof message === "object") {

        displayMessage = message.message;
    }

    return displayMessage;
}

export const equalArray = (a, b) => {

    if (a.length === b.length) {

        for (var i = 0; i < a.length; i++) {

            if (a[i] !== b[i]) {

                return false;
            }
        }
        return true;

    } else {

        return false;
    }
}

export const isValidHttpUrl = (site_url) => {
    let url;
    try {
        url = new URL(site_url);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const convertArrayToSting = (value, separetor) => {
    let result = '';

    if (Array.isArray(value)) {
        return value.reduce((acc, cur) => `${acc}${acc ? separetor : ''}${cur}`, '');
    }

    return result;
}

export const convertStringToArray = (value) => {
    let result = [];

    if (value.includes('#')) {

        result = value.split('#')
            .map(item => item.trim())
            .filter(item => item !== "");
    }
    else {
        result.push(value);
    }

    return result;
}