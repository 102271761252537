import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: "menu",
    initialState: {

        isOpen: [],

        opened: true,

        sideNavList: []
    },
    reducers: {
        menuToggle: (state, { payload }) => {

            state.isOpen = [payload.id]
        },

        setMenu: (state, { payload }) => {

            state.opened = payload.opened
        },

        getSideNavList: (state, { payload }) => {

            state.sideNavList = payload
        }
    }
});

export const { menuToggle, setMenu, getSideNavList } = menuSlice.actions;

export const menuState = (state) => state.menu;

export default menuSlice.reducer;