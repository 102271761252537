import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getEmptyData } from '../../utils/empty-types';
import { getErrorMessage } from 'utils';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const getUserAsync = createAsyncThunk('getUserAsync', async ({ dispatch }) => {
    dispatch(loaderStart());

    return await Response('GET_ALL_USERS')
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success && data.resultObj.data) console.log('first', data.resultObj.data);
            return data.resultObj.data;

            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return [];
        });
});

export const addUserAsync = createAsyncThunk('addUserAsync', async ({ dispatch, formData }) => {
    dispatch(loaderStart());

    return await Response('ADD_USER', formData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(setAlertMessage('User added successfully'));

                dispatch(getUserAsync({ dispatch }));
            }
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));
        });
});

export const deleteUserAsync = createAsyncThunk('deleteUserAsync', async ({ userId, dispatch }) => {
    dispatch(loaderStart());

    return await Response('DELETE_USER', { _id: userId })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };
                dispatch(getUserAsync({ dispatch, payload }));
                dispatch(setAlertMessage('User Delete successfully'));
                return [];
            }
            return [];
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));
        });
});

export const updatePasswordAsync = createAsyncThunk('updatePasswordAsync', async ({ dispatch, postData }) => {
    dispatch(loaderStart());

    return await Response('UPDATE_PASSWORD', postData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };

                dispatch(getUserAsync({ dispatch, payload }));

                dispatch(setAlertMessage('Password updated successfully'));

                return data?.resultObj;
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return {};
        });
});

export const updatePasswordByIdAsync = createAsyncThunk('updatePasswordByIdAsync', async ({ dispatch, postData }) => {
    dispatch(loaderStart());

    return await Response('UPDATE_PASSWORD_BY_ID', postData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };

                dispatch(getUserAsync({ dispatch, payload }));

                dispatch(setAlertMessage('Password updated successfully'));

                return data?.resultObj;
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return {};
        });
});
export const editUserAsync = createAsyncThunk('editUserAsync', async ({ userId, dispatch }) => {
    dispatch(loaderStart());

    return await Response('UPDATE_USER_BY_ID', { userId })
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success && data?.resultObj) return data?.resultObj;
            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));
            return {};
        });
});
export const updateUserProfileAsync = createAsyncThunk('updateUserProfileAsync', async ({ dispatch, postData }) => {
    debugger;
    dispatch(loaderStart());
    console.log('postData', postData);
    debugger;
    return await Response('UPDATE_USER', { postData })
        .then((data) => {
            debugger;
            dispatch(loaderEnd());

            if (data.success) {
                const payload = { isDeleted: false };
                dispatch(getUserAsync({ dispatch, payload }));

                dispatch(setAlertMessage('User Data updated successfully'));

                return data?.resultObj;
            }

            return {};
        })
        .catch((error) => {
            dispatch(loaderEnd());
            dispatch(setAlertMessage(getErrorMessage(error.message)));

            return {};
        });
});
export const manageUserSlice = createSlice({
    name: 'users',
    initialState: {
        manageUserData: [],
        editUserData: {}
    },
    reducers: {
        clearUserState: (state, { payload }) => {
            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {
        //get
        [getUserAsync.fulfilled]: (state, { payload }) => {
            state.manageUserData = payload;
        },
        //edit
        [editUserAsync.fulfilled]: (state, { payload }) => {
            state.editUserData = payload;
        }
    }
});

export const { clearUserState } = manageUserSlice.actions;

export const manageUserState = (state) => state.users;

export default manageUserSlice.reducer;
